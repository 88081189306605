.footer {
    background-color: var(--bg-3);
    margin-block-start: calc(var(--padding) * 2);
    border: 1px solid var(--border-muted);
    position: sticky;
    top: 100vh;
}

.made_with_love {
    display: block;
    text-align: center;
    padding-block-start: var(--padding);
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-block-end: var(--padding);
    max-width: 1200px;
    padding: var(--padding) 0;

    a {
        color: black;
    }
}

.footer_nav {
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: 1200px;
    padding: 0 var(--padding);

    display: grid;
    grid-gap: 2vmin;
    grid-template-columns: repeat(4, minmax(0, max-content));
    justify-content: space-between;
    padding: calc(var(--padding) * 2) 0;

    div {
        span {
            font-size: 1rem;
            font-weight: var(--font-weight-bold);
        }

        ul {
            list-style-type: none;
            padding: 0;

            li {
                padding: var(--padding-tiny) 0;
                font-size: 1rem;
                font-weight: var(--font-weight-normal);

                a {
                    text-decoration: none;
                    color: var(--grey);
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .footer_nav {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding-block: calc(var(--padding) * 2) 0;
        padding-inline: var(--padding);
    }
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
    .footer {
        .footer_nav {
            max-width: 90%;
        }
    }
}
